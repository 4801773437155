.project-main-container {
    height: 50vh;
    width: 70vw;
    background-color: transparent;
    box-shadow: 0 0 13px #0d5c63;
    border-radius: 10px;
    overflow-y: auto;
  
    @media (max-width: 768px) {
      height: 70vh;
      width: 100vw;
      box-shadow: none;
    }
}

.modal-body {
    background-color: black;
    img {
        width: 100%;
        height: auto;
        padding: 1rem;
        border-radius: 30px;
    }
    .description-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        border-radius: 10px;
        width: 80%;
        h6 {
            display: flex;
            align-items: center;
            text-align: center;
            color: #fff;
            margin: 0;
        }
    }
}

.modal-header {
    color: #fff;
    border-bottom: none !important;
    background-color: rgb(0, 0, 0);
    padding: 0 !important;
}

@media (max-width: 576px) {
    .modal-dialog {
        max-width: 100%;
        margin: 0;
    }
    .modal-content {
        height: 100vh;
    }
}


