.content-container {
  @media (max-width: 768px) {
    margin-top: 60px;
  }
}

.chat-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  @media (max-width: 768px) {
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.chat-wrapper {
  width: 500px;
  height: 70vh;
  border-radius: 20px;
  box-shadow: 0px 4px 14px 2px rgba(0, 0, 0, 0.6);
  overflow: hidden;

  @media (max-width: 768px) {
    width: 100vw;
    height: 100vh;
    
    border-radius: 0;
  }
}

.chat-button {
  position: fixed;
  z-index: 1000;
  bottom: 0;
  right: 0;
  margin-right: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px 20px;
  background-color: var(--background-color);
  color: var(--text-color);
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0 -2px 5px rgba(0,0,0,0.2);
  width: 25%;
  text-align: center;
}