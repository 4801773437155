.icon {
    width: 10rem; /* default size */
}

/* For medium devices (tablets, less than 768px) */
@media (max-width: 768px) {
    .icon {
        width: 7rem;
    }
}

/* For small devices (phones, less than 576px) */
@media (max-width: 576px) {
    .icon {
        width: 5rem;
    }
}