.certification-image {
    max-width: 100%;
    max-height: 90vh;
    width: auto;
    height: auto;
    object-fit: contain;
    padding: 20px;

}

@media (min-width: 769px) {
    .certification-image {
        max-width: 50%;
    }
}